
html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #1f0060;
}

.purple {
  color: #b061df !important;
}

/* ----------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #1f0060;
  background-image: url(./images/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #212038d8 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  padding: 0.25rem 1.5rem !important;
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbarmain{
  margin-left: 210px;
}
.navbar-toggler span {
  display: block !important;
  background-color: #be50f4 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}
@media (max-width: 1400px) {
  .navbarmain{
    margin-left: 200px;
  }
}
@media (max-width: 1050px) {
  .navbarmain{
    margin-left: 50px;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
  .img-fluid {
    width: 230px !important;
  }
  .img-home-main{
    width: 100% !important;
    text-align: center !important;
    padding-top: 40px !important;
  }
  .navbarmain{
    margin-left: 0px;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}
.img-fluid {
  width: 384px;
}
.logo {
  height: 2em !important;
  width: 2em !important;
  border-radius: 50%;
}

.navbar-nav .nav-link {
  color: #fbd9ad !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
  #navhead{
    font-size: 1.05rem;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #fbd9ad;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 0px !important;
}

.home-section {
  position: relative;
  z-index: 2;
  background-image: var(--image-gradient), url(./images/h3.jpg);
  background-position: top center;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding-bottom: 22px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: #fbd9ad;
  text-align: left;
  z-index: 6;
}

.heading {
  font-size: 4.4em !important;
  padding-left: 45px !important;
}

.heading-name {
  font-size: 4.0em !important;
  padding-left: 45px !important;
}

.main-name {
  /* color: #cb70ff; */
  color: #cb70ff;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #cb70ff !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #cb70ff !important;
}
.typing-homeclass{
  padding-left: 45px;
  text-align: left;
  font-size: 1.8em;
}
.sociallink-home{
  padding-left: 40px;
  text-align: left;
  padding-top: 35px;
}
@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.2em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
  .heading {
    font-size: 3em !important;
    padding-left: 16px !important;
  }
  .home-content {
    padding: 6rem 0 2rem !important;
  }
  .heading-name {
    font-size: 3.0em !important;
    padding-left: 16px !important;
  }
  .typing-homeclass{
    padding-left: 16px;
  }
  .sociallink-home{
    padding-left: 10px;
    text-align: left;
    padding-top: 55px;
  }
  .socialicons{
    padding-top: 10px;
    padding-right: 3px !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
  
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  color:#fbd9ad;
}
.certificate-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 0px !important;
  color:#fbd9ad;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}
.certificate-description {
  color: white !important;
  padding-top: 0px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 151px !important;
  height: 151px !important;
  text-align: center !important;
  font-size: 6.1em !important;
  line-height: 1.25em !important;
  background: rgba(251, 217, 173, 0.972) !important;
  border-radius: 20% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 18px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 40px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
  z-index: 6;
}
.footer-copywright {
  text-align: center !important;
  z-index: 7;
}

.footer-body {
  text-align: center !important;
  z-index: 7;
}
.aboutme-heading{
  font-size: 4.6em;
  color:#fbd9ad;
}
@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
  .home-about-description {
    padding-top: 0px !important;
  }
  .aboutme-heading{
    font-size: 3.6em;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
  z-index: 25;
}

.blockquote-footer {
  color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #623686 !important;
  border-color: #623686 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 25px !important;
  padding-bottom: 20px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}



@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #934cce5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}
.fork-btn-inner::after {
  display: none !important;
}



.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link.active {
  color: #a12be6 !important;
  background-color: #fbd9ad;
  border-color: #fbd9ad #fbd9ad #fbd9ad;
  font-size: 17px;
  font-weight:600;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #fbd9ad #fbd9ad #fbd9ad !important;
  isolation: isolate !important;
  color:#fbd9ad !important;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #fbd9ad !important;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.Navitem .nav-tabs .nav-link:focus,.Navitem .nav-tabs .nav-link:hover {
  border-color: #b061df #b061df #b061df;
  isolation: isolate;
}

.nav-tabs {
  border-bottom: 1px solid #fbd9ad !important;
}

.progress {
  display: flex;
  height: 0.2rem !important;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  width:100%;
}
#com{
  display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #fe3e57;
    transition: width .6s ease;
    width: 55%;
}
#rust{
  display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #433efe;
    transition: width .6s ease;
    width: 55%;
}
#pm{
  display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #54faae;
    transition: width .6s ease;
    width: 55%;
}
#ps{
  display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #ff8c2f;
    transition: width .6s ease;
    width: 55%;
}
#aa{
  display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #f1f965;
    transition: width .6s ease;
    width: 55%;
}
#org{
  display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #ff0173;
    transition: width .6s ease;
    width: 55%;
}
#cr{
  display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #39c4ff;
    transition: width .6s ease;
    width: 55%;
}
#react{
  display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #41f2ff;
    transition: width .6s ease;
    width: 55%;
}

@media only screen and (min-width: 1170px){
.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    left: 83% !important;
}
}

@media only screen and (min-width: 1170px){
.vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
    left: auto !important;
}
}



.homeaboutsocial {
  /* text-align: center !important; */
  padding-top: 25px;
  color: white !important;
}

.homeaboutsociallinks {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.homesocialicons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.4em !important;
  line-height: 1.5em !important;
  background: rgba(251, 217, 173, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.homesocialicons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.homesocialicons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.homesocialicons:hover {
  color: #87209e;
  box-shadow: 0 0 25px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.socialicons {
  display: inline-block !important;
  padding-right: 10px;
  padding-left: 10px;
}

.iconcolour {
  color: #700c86 !important;
}

.cert-card {
  width: 100%;
  height: 100%;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #700c86;
  border-radius: 5px;
  box-shadow: 2px 2px 10px #700c86;
  margin-bottom: 30px;
  flex: 0 0 30%;
  transition: all 0.4s ease 0s !important;
}
.cert-card:hover {
  transform: scale(1.05) !important;
  transition: all 0.9s ease 0s !important;
}

.cert-header {
  margin: 0px;
  border-radius: 5px 5px 0px 0px;
}

.logo_img {
  margin: 3px;
  width: auto;
  height: 100px;
}

.cert-body {
  margin: 0px;
  height:max-height;
}

.cert-body-title {
  font-family: "Google Sans Regular";
  font-size: 20px;
  padding: 10px;
  padding-bottom: 0px;
  color:black;
}

.cert-body-subtitle {
  font-family: "Google Sans Regular";
  font-size: 18px;
  color: #808080;
  padding: 10px;
}

.content {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  font-family: "Google Sans Regular";
  letter-spacing: 0.15em;
  margin-bottom: 0em;
  text-transform: uppercase;
}

.fadeIn-top {
  top: 20%;
}

@media (max-width: 1380px) {

}

@media (max-width: 768px) {
  .cert-card {
    width: 100%;
  }
  .comingsoonclass{
    height: 500px !important;;
  }
}

.blog--viewAll {
  width: 100%;
  margin-top: 1rem;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.blog--viewAll a{
  text-decoration: none;
  color:#fbd9ad;
}

.blog--viewAll a button {
  outline: none;
  border: none;
  width: 150px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: inherit;
  border-radius: 45px;
  font-size: 1.05rem;
  font-family: var(--primaryFont);
  font-weight: 500;
  padding-left: 1.5rem;
  cursor: pointer;
  color:#fbd9ad !important;
}
.blog--viewAll a button:hover {
  color:#fbd9ad !important;
}

.viewArr {
  color: #fbd9ad;
  width: 40px;
  height: 40px;
  padding: 0.5rem;
  font-size: 1.05rem;
  border-radius: 50%;
  cursor: pointer;
}
.viewArr:hover {
  color: #fbd9ad ;
}


@media screen and (max-width: 600px) {
  .blog--viewAll {
      position: relative;
      margin-top: 2.5rem;
  }
  .blog--viewAll a {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);  
  }
}

.singleProject {
  box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
  width: 104%;
  height: 370px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.4rem 2rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.projectContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.projectContent > h2 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 600;
  font-size: 1.925rem;
  line-height: 110%;
  text-align: center;
}

.projectContent > img {
  width: 100%;
  height: 60%;
  transition: opacity 0.7s 0.3s;
}

.singleProject:hover img{
  opacity: 0;
}

.project--showcaseBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 0px 20px;
}

.project--showcaseBtn > a {
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.5s 0.3s;
}

.singleProject:hover .project--showcaseBtn a:nth-child(2) {
  transform: translateX(-140px) scale(1.1) !important;
}


.singleProject .project--desc{
  position: absolute;
  width: 95%;
  height: 200px;
  left: 0;
  top: 25px;
  transform: translate(-110%,40%);
  padding: 15px;
  border-radius: 0 20px 20px 0;
  transition: transform 0.9s;
  color:rgb(147, 71, 192)!important;
  line-height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--primaryFont);
}

.singleProject:hover .project--desc{
  transform: translate(-2%,40%);
}

.singleProject .project--lang{
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 140px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  color:rgb(147, 71, 192)!important;
  gap: 0.5rem;
  flex-direction: column;
  border-radius: 10px 0 0 10px;
  transform: translateX(100%);
  transition: transform 0.5s 0.3s;
  padding: 0.825rem;
}

.singleProject:hover .project--lang{
  transform: translateX(0);
}

.project--lang > span {
  font-family: var(--primaryFont);
  font-weight: 500;
  word-break: break-word; 
  line-height: 100%;
}

.iconBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 2px solid #fbd9ad;
  color: #fbd9ad;
  transition: all 0.2s;
  
}
.iconBtn:hover {
    background-color: #fbd9ad;
    color: rgb(93 43 123);
    transform: scale(1.1);
    border: 2px solid rgb(93 43 123);
}
  
.icon {
  font-size: 1.1rem;
  transition: all 0.2s;
}

.input-class{
  border: 4px solid #fbd9ad;
  background-color: #150d23;
  color: #fbd9ad;
  font-weight: 500;
  transition: border 0.2s ease-in-out;
}

.form-input {
  height: 50px;
  box-sizing: border-box;
  border-radius: 50px; 
  outline: none;
  margin-bottom: 2rem;
  padding: 0.7rem 0.7rem;
  border-radius: 20px;
  width: 100%; 
  resize: none;
}
.form-input::placeholder{
  color:#fbd9ad;
}

.input-container {
  width: 100%;
}

.contacts-form {
  display: flex;
  flex: 0.4;
  width: 100%;
}

.contacts-form form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}

.label-class{
  background-color: #150d23;
  color: #fbd9ad;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0 5px;
  transform: translate(25px,50%);
  display: inline-flex;
  width: fit-content;
}

.form-message {
  height: 150px;
  box-sizing: border-box;
  border-radius: 50px; 
  outline: none;
  margin-bottom: 2rem;
  padding: 0.7rem 0.7rem;
  border-radius: 20px;
  width: 100%;
  resize: none;
}
.form-message::placeholder{
  color:#fbd9ad;
}

.contacts-body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.submit-btn button {
  border: none;
  outline: none;
  width: 140px;
  height: 50px;
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.submitBtn{
  background-color: #fbd9ad;
  color: #742586;
  transition: 250ms ease-in-out;
  font-weight: 700;
}

.send-icon {
  font-size: 25px;
  transform-origin: center;
  transform: translate(5px,-3px) rotate(-30deg);
}

.contact-map iframe {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 200px;
}

.contact-span{
  color: #ff6e36;
  font-size: 20px;
  padding-right: 20px;
}

.contact-para{
  border-left: 1px solid lightgrey;
  padding-left: 20px;
  margin: 0;
}

.detailsIcon{
  background-color: #fbd9ad;
  color: #742586;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  transition: 250ms ease-in-out;
  flex-shrink: 0;
}
.detailsIcon:hover{
  transform: scale(1.1);
  color: #fbd9ad;
  background-color: #742586;
  cursor: pointer;
}

.contacts-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 0.6;
  box-sizing: border-box;
  padding-left: 7rem;
  margin-top: 1.2rem;
}

.contact-map{
  padding-left: 7rem;
}
@media (max-width: 767px) {
  .contacts-details {
    padding-left: 0.5rem;
    padding-top: 20px !important;
  }
  .personal-details p {
    width: 95% !important;
  }
  .contact-map{
    padding-left: 0.8rem;
    padding-right: 20px;
  }
}


.personal-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2.5rem;
  text-decoration: none;
}

.personal-details p {
  padding-top:10px;
  margin-left: 1.5rem;
  font-size: 18px;
  line-height: 110%;
  font-weight: 500;
  width: 57%;
  word-break: break-word;
  text-align: left;
}



.singleProjectPage {
  box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
  width: 100%;
  height: 420px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.4rem 2rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.projectPageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.projectPageContent > h2 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 110%;
  color: '#fbd9ad'!important;
  text-align: center;
}

.projectPageContent > img {
  width: 100%;
  height: 60%;
  transition: opacity 0.7s 0.3s;
}

.singleProjectPage:hover img{
  opacity: 0;
}

.project--showcaseBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.project--showcaseBtn > a {
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.5s 0.3s;
}

.singleProjectPage:hover .project--showcaseBtn a:nth-child(2) {
  transform: translateX(-225px) scale(1.1) !important;
}


.singleProjectPage .project--desc{
  position: absolute;
  width: 95%;
  height: 200px;
  left: 0;
  top: 25px;
  transform: translate(-110%,40%);
  padding: 15px;
  border-radius: 0 20px 20px 0;
  transition: transform 0.9s;
  line-height: 110%;
  color:rgb(147, 71, 192)!important;
  font-size: 1.1rem!important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--primaryFont);
}

.singleProjectPage:hover .project--desc{
  transform: translate(-2%,40%);
}

.singleProjectPage .project--lang{
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 240px;
  font-size: 0.95rem;
  display: flex;
  color: rgb(147, 71, 192)!important;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
  border-radius: 10px 0 0 10px;
  transform: translateX(100%);
  transition: transform 0.5s 0.3s;
  padding: 0.825rem;
}

.singleProjectPage:hover .project--lang{
  transform: translateX(0);
}

.project--lang > span {
  font-family: var(--primaryFont);
  font-weight: 500;
  word-break: break-word; 
  line-height: 100%;
}

.iconBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  border: 2px solid #fbd9ad;
  color: #fbd9ad;
  transition: all 0.2s;
  
}
.iconBtn:hover {
    background-color: #fbd9ad;
    color: rgb(93 43 123);
    transform: scale(1.1);
    border: 2px solid rgb(93 43 123);
}

@media (max-width: 470px) {
  .singleProjectPage .project--lang{
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 160px;
    font-size: 0.95rem;
    display: flex;
    color: rgb(147, 71, 192)!important;
    justify-content: center;
    gap: 0.5rem;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    transform: translateX(100%);
    transition: transform 0.5s 0.3s;
    padding: 0.825rem;
  }
  .singleProjectPage:hover .project--showcaseBtn a:nth-child(2) {
    transform: translateX(-137px) scale(1.1) !important;
  }
  
}
@media (max-width: 360px) {
  .singleProjectPage .project--lang{
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 150px;
    font-size: 0.95rem;
    display: flex;
    color: rgb(147, 71, 192)!important;
    justify-content: center;
    gap: 0.5rem;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    transform: translateX(100%);
    transition: transform 0.5s 0.3s;
    padding: 0.825rem;
  }
  .singleProjectPage:hover .project--showcaseBtn a:nth-child(2) {
    transform: translateX(-128px) scale(1.1) !important;
  }
  
}
#resume{
  height:800px;
}